import React from "react";
import general from "general/general";

function useAlert(endpoint) {
  const [alert, setAlert] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isInitialFetch, setIsInitialFetch] = React.useState(false);
  const [shouldRefetch, setShouldRefetch] = React.useState(false);

  React.useEffect(() => {
    async function _fetchAlert() {
      const result = await general.callApi(
        endpoint,
        "GET",
        {},
        null,
        null,
        null,
        true,
        false,
        true
      );
      if ("alert" in result) {
        setAlert(result.alert);
      }
      setIsLoading(false);
      setShouldRefetch(false);
    }
    if (!isInitialFetch) {
      _fetchAlert();
      setIsInitialFetch(true);
    } else if (shouldRefetch) {
      setIsLoading(true);
      _fetchAlert();
    }
  }, [endpoint, shouldRefetch]);

  return [alert, setAlert, isLoading, setShouldRefetch];
}

export { useAlert };
